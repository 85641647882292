* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body {
  display: flex;
  flex-direction: column;
}
a {
  color: inherit;
}
svg {
  display: block;
  margin: 0 auto;
  width: 1em;
  height: 1em;
  font-size: 1em;
}
img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.abbreviation {
  text-transform: uppercase;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 255, 255);
  border-bottom: 0.125em solid rgb(239, 239, 239);
  padding: 0px 0.8em;
  min-height: 50px;
}
header nav {
  font-size: 0.875em;
}
header nav a {
  color: #595959;
  display: inline-block;
  margin: 0 0.6em;
}
header nav a span {
  display: inline-block;
  vertical-align: middle;
}
header nav a span.icon {
  padding-right: 0.4em;
}
header nav a:hover .text {
  text-decoration: underline;
}
main {
  background-color: #ffffff;
  flex: 1;
}
main .content {
  background-position: center center;
  background-size: cover;
  padding: 2.1875em 0px 2em;
}
.screen-reader-only {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.stepped-survey {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  margin: 0 auto;
  max-width: 850px;
  min-height: 600px;
  padding: 1em;
  position: relative;
  overflow: hidden;
}
.notification-wrapper {
  left: 0;
  right: 0;
  top: 1em;
  position: absolute;
  z-index: 5;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: none;
}
.notification-wrapper.active {
  display: block;
}
.notification {
  background: #f2dede;
  border: 0.04444444em solid #ecbfbe;
  border-radius: 0.17777778em;
  color: #a94442;
  display: inline-block;
  font-size: 1.125em;
  padding: 0.8em 1em;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}
.notification-icon,
.notification-text {
  display: inline-block;
  vertical-align: top;
}
.notification-text {
  font-size: 1em;
  line-height: 1;
  padding-left: 0.3em;
  white-space: normal;
}
.stepped-survey[data-step="0"] button.previous {
  display: none;
}
.stepped-survey button.previous {
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 2;
  font-size: 2.2em;
  background: none;
  border: 0;
  margin-top: -0.5em;
  left: 3%;
  outline: none;
  opacity: 0.5;
}
.stepped-survey button.previous svg {
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.6));
}
.stepped-survey button.previous:hover {
  opacity: 1;
}
.stepped-survey .steps {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.stepped-survey .step {
  display: block;
  width: 100%;
}
.stepped-survey .step input,
.stepped-survey .step select,
.stepped-survey .step button {
  font-family: inherit;
}
.stepped-survey .step.next,
.stepped-survey .step.previous {
  /* opacity: 0; */
  position: absolute;
  top: 0;
}
.stepped-survey .step.next {
  left: 100%;
}
.stepped-survey .step.previous {
  left: -100%;
}
.stepped-survey .step:not(.active):not(.previous):not(.next) {
  /* display: none; */
  left: 100%;
  position: absolute;
  top: 0;
}
.stepped-survey .step.movingLeft {
  opacity: 1;
  transition: margin-left 0.7s ease-in-out;
  margin-left: -100%;
}
.stepped-survey .step.movingRight {
  opacity: 1;
  transition: margin-left 0.7s ease-in-out;
  margin-left: 100%;
}
.stepped-survey .step .heading {
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.1;
}
.stepped-survey .step .inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  clear: both;
  margin: 1em auto 1.8em;
  width: auto;
}
.stepped-survey .step .block:not(.radio) .inputs {
  justify-content: space-between;
  width: 90%;
  max-width: 567px;
}

.stepped-survey .step .inputs .question-label {
  display: block;
  font-size: 1.2em;
  font-weight: 700;
  margin-top: 0.8em;
  text-align: center;
  width: 100%;
}

.stepped-survey .step .block > .label:empty {
  display: none;
}

.stepped-survey .step .inputs label:first-of-type + .question-label {
  margin-top: 0;
}

.stepped-survey .step .block.radio input {
  display: none;
}
.stepped-survey .step .block.radio .option {
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  padding: 0.5em 1.5em;
}
.stepped-survey .step input:not([type="checkbox"]):not([type="radio"]),
.stepped-survey .step select {
  display: inline-block;
  line-height: 1.42857;
  color: rgb(85, 85, 85);
  font-size: 0.8em;
  background-color: rgb(255, 255, 255);
  background-image: none;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  font-weight: 700;
  height: 2.125em;
  padding: 6px 12px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-image: initial;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  margin: 0.5em;
  flex: 1;
  min-width: 96%;
}
.stepped-survey .step input:not([type="checkbox"]):not([type="radio"]):focus,
.stepped-survey .step select:focus {
  border: 1px solid #558ee5;
}
.stepped-survey .step input.half:not([type="checkbox"]):not([type="radio"]),
.stepped-survey .step select.half {
  min-width: 0%;
}
.stepped-survey .step .next-step,
.stepped-survey .step .final-step {
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 1.2em;
  line-height: 1;
  margin: 1em auto;
  max-width: 250px;
  padding: 0.5em 1.25em;
  outline: none;
  width: 96%;
}
.stepped-survey .step .final-step {
  max-width: 540px;
  width: 86%;
}
.stepped-survey .step .final-step span {
  display: inline-block;
  vertical-align: middle;
}
.stepped-survey .step .final-step span.icon {
  padding-left: 0.5em;
}
.stepped-survey .step.dob .block:not(.radio) .inputs {
  width: 96%;
  max-width: 567px;
}
.stepped-survey .step.dob .block:not(.radio) .inputs label {
  display: inline-block;
  flex: 1;
  font-size: 0.8em;
  font-weight: 700;
  color: #555;
}
.stepped-survey .step.dob .block:not(.radio) .inputs select {
  flex: 2;
  min-width: 0;
}
.stepped-survey .inputs .phone {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  clear: both;
  margin: 0 auto;
}
.stepped-survey .inputs .phone .countryCode {
  display: inline-block;
  text-align: right;
  padding-right: 0.4em;
}
.stepped-survey .inputs .phone .fields {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: row;
  max-width: 100%;
}
.stepped-survey .inputs .phone .fields input {
  flex: 1;
  min-width: 0;
  max-width: 4.6875em;
  margin: 0.2em;
}
.stepped-survey .step .inputs .email-wrapper {
  width: 100%;
}
.stepped-survey .step .inputs .checkbox {
  font-size: 12px;
  line-height: 1.3;
  display: flex;
  padding: 0.5em;
  width: 96%;
  margin: 0.5em auto;
}
.stepped-survey .step .inputs .checkbox .field {
  padding-right: 1.5em;
  padding-top: 0.1em;
}
.stepped-survey .step .inputs .checkbox .field input {
  transform: scale(1.5);
}
.stepped-survey .step .inputs .checkbox .label {
  margin: 0;
  text-align: left;
  font-size: 1em;
  line-height: 1.3;
  text-shadow: none;
  flex: 1;
}

.stepped-survey .step .inputs .checkbox {
  /* background: #f5f5f5;
  border: 1px solid #bfcaba;
  border-radius: 0.5em; */
  padding: 0;
  overflow: hidden;
}
.stepped-survey .step .inputs .checkbox .field {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 0.6em 1.2em;
}
.stepped-survey .step .inputs .checkbox.tcpa .field {
  /* background: #3bb100; */
}
.stepped-survey .step .inputs .checkbox .label {
  padding: 0.6em;
}

#email {
  margin-bottom: 0;
}
#email-validation {
  background: yellow;
  font-size: 0.8em;
  border-radius: 0px 0px 0.25em 0.25em;
  color: #4e4e4e;
  font-weight: bold;
  width: 96%;
  margin: 0 auto 0.5em;
  padding: 0.2em;
}
#email-validation:not(.changed) {
  padding: 0;
}
#email-validation:not(.changed) .message,
#email-validation:not(.changed) .cog {
  display: none;
}
#email-validation.loading .message {
  display: none;
}
#email-validation.loading .cog {
  display: block;
}
#email-validation .message {
  display: block;
  padding: 0.245em;
}
#email-validation .cog {
  display: none;
  font-size: 1.2em;
  padding: 0.2em 0;
}
#email-validation .cog img {
  animation: spin 2s linear infinite;
  width: 1em;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.stepped-survey nav {
  font-size: 0.75em;
}
.stepped-survey nav a {
  color: inherit;
  text-decoration: none;
  margin: 0px 0.5em;
}
.stepped-survey nav a:hover {
  text-decoration: underline;
}
section.details {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4em 1em 2em;
  max-width: 1500px;
  margin: 0 auto;
}
section.details .detail {
  align-items: flex-start;
  display: flex;
  flex: 1;
  font-size: 1.25em;
  justify-content: flex-start;
  padding: 0 1em;
}
section.details .detail .icon {
  font-size: 1.45em;
  margin-right: 0.4em;
}
section.details .detail .text {
  text-align: left;
}
section.disclaimer {
  border: 1px solid #eee;
  border-left: 0px;
  border-right: 0px;
  font-size: 0.875em;
  letter-spacing: -0.03571429em;
  line-height: 1.42857;
  margin: 2.28571429em auto;
  max-width: 1350px;
  padding: 1.71428571em 0;
  text-align: left;
  width: 90%;
}
footer {
  border-top: 1px solid #e7eaec;
  font-size: 0.75em;
  letter-spacing: -0.04166667em;
  padding: 0.83333333em 1.66666667em;
}
footer a {
  color: #2a5a8e;
  text-decoration: none;
}
footer a:hover {
  color: #265883;
  text-decoration: underline;
}
#marketingPartners-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
}
#marketingPartners-wrapper.active {
  display: flex;
}
#marketingPartners-dialog {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px;
  width: 90%;
  position: relative;
  text-align: left;
  font-family: arial, sans-serif;
  font-weight: 700;
  font-size: 12px;
  box-sizing: border-box;
  max-width: 400px;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 0.5em 28px 0.5em 1em;
  color: #000000;
}
#marketingPartners-close {
  -webkit-appearance: none;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  border-radius: 50px;
  border: 2px solid black;
  background: rgb(255, 255, 255);
}
#marketingPartners-content p {
  margin: 1em 0;
}
.brand-icons-headline {
  font-size: 1.25em;
  font-weight: 700;
  padding: 1em;
}
.brand-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin: 0 auto;
}
.brand-icons img {
  margin: 0 1em;
}

.helpAndSupport {
  position: relative;
}

.helpAndSupport:before {
  display: block;
  content: "";
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 46%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.helpAndSupport svg.icon {
  position: absolute;
  top: -16px;
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  width: 75px;
  height: 47px;
}

.helpAndSupport svg.icon .cls-1 {
  fill: #cccccc;
}

.helpAndSupport svg.icon .cls-2 {
  fill: #000000;
}

.helpAndSupport .headline {
  padding-top: 1.2em;
}

@media screen and (max-width: 1470px) {
  .brand-icons {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 800px) {
  section.details {
    display: block;
    padding: 2em 1em 1em;
  }
  section.details .detail {
    padding: 0.5em 0em;
  }
}
@media screen and (max-width: 768px) {
  .stepped-survey {
    padding: 2%;
    height: 100%;
  }
}
@media screen and (max-width: 580px) {
  section.details .detail {
    font-size: 3.448276vw;
  }
  .stepped-survey .step.dob .block:not(.radio) .inputs label {
    display: inline-block;
    flex: 2;
    min-width: 25%;
    text-align: right;
  }
  .stepped-survey .step.dob .block:not(.radio) .inputs select {
    flex: 2;
    min-width: 70%;
    margin: 2%;
  }
}
@media screen and (max-width: 440px) {
  .stepped-survey button.previous {
    font-size: 10vw;
    left: 0%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #cccccc;
}