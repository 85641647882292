body {
  background: #ffffff;
}

.emphasis {
  color: #feac00;
  text-decoration: underline;
}

header .logo {
  display: block;
  max-width: 238px;
  width: 100%;
  padding: 0.5em 0.3125em;
}

main .content {
  background-image: url("../images/background.jpg");
  padding-bottom: 4em;
}

section.details .detail .icon {
  color: #649f04;
}

.stepped-survey {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.75em;
  box-shadow: 0.05em 0.05em 0.25em rgba(0, 0, 0, 0.3);
  color: #777777;
}
.stepped-survey .step .heading {
  padding-top: 1.6em;
}
.stepped-survey .step .block > .label {
  color: #6d8763;
  font-size: 1.4em;
  font-weight: 500;
  text-shadow: white 1px 1px;
  text-transform: capitalize;
  margin: 1em 0;
}

.stepped-survey .step .block.radio .option {
  background: #feac00;
  border: 1px solid #ffffff;
  color: #ffffff;
  /* color: #2E2E2E; */
}

.stepped-survey .step .block.radio input:checked + .option {
  box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px inset;
}

.stepped-survey .step .block.radio label:nth-of-type(2) {
  border-radius: 8px 0px 0px 8px;
}

.stepped-survey .step .block.radio .option:last-of-type {
  border-radius: 0px 8px 8px 0px;
  border-left: 0;
}

.stepped-survey .step .inputs .checkbox .label {
  color: #555;
}

.stepped-survey .step .inputs .checkbox .label a {
  color: #15a5b4;
  font-weight: 400;
  text-decoration: none;
}
.stepped-survey .step .inputs .checkbox .label a:hover {
  color: #804a9d;
  text-decoration: underline;
}

.stepped-survey .step .next-step,
.stepped-survey .step .final-step {
  background: #feac00;
  border: 1px solid #ffffff;
  border-radius: 0.33333333em;
  color: #ffffff;
  /* color: #2E2E2E; */
}

@media screen and (max-width: 768px) {
  main .content {
    padding: 0;
  }
  .stepped-survey {
    border-radius: 0;
    box-shadow: none;
  }
}
@media screen and (max-width: 540px) {
  header .logo {
    min-width: 176.281px;
    width: 44.074074vw;
  }
}
@media screen and (max-width: 440px) {
  .stepped-survey .step .label {
    font-size: 6.363636vw;
  }
}
